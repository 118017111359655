import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }



  getData(url: string, options?: any) {
    return this.http.get(url, options);
  }
  postData(url: string, body: any, options?: any) {
    if(options){
      return this.http.post(url, body, {...options});
    }
    return this.http.post(url, body);
  }
  putData(url: string, body: any, options?: any) {
    return this.http.put(url, body, options);
  }
  deleteData(url: string, options?: any) {
    return this.http.delete(url, options);
  }
}

// interface HttpOptions {
//   headers?: HttpHeaders | {
//     [header: string]: string | string[];
//   };
//   context?: HttpContext;
//   observe?: 'body';
//   params?: HttpParams | {
//     [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
//   };
//   reportProgress?: boolean;
//   responseType?: 'json';
//   withCredentials?: boolean;
//   transferCache?: {
//     includeHeaders?: string[];
//   }
// }