export interface SellerDetails {
  catalogId?: string,
  companyLogoId: string,
  companyName: string
  sellerId: string,
  privacy?: string,
  catalogName?: string,
  templateName?: string,
  collectionId?: string
  isExpired: boolean,
  description: string,
  mobileNumber: string
}

export function sellerDetailsDto(data: any): SellerDetails {

  return {
    catalogId: data['catalogId'],
    companyLogoId: data['companyLogoId'],
    companyName: data['companyName'],
    sellerId: data['sellerId'],
    privacy: data['privacy'],
    catalogName: data['catalogName'],
    templateName: data['templateName'],
    isExpired: data["isCatalogExpired"],
    description: data["description"],
    mobileNumber: data['mobileNumber'].replace('+91-', '')
  }
}