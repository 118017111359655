import { environment } from "../environments/environment";
  
export const loginUrls = {
    sellerInfo: environment.gateWayHost + '/gate/sales-wizz/cat/product/catalog-share/get/seller-info/',
    sellerInfoCustomOrder: environment.gateWayHost + '/gate/sales-wizz/cat/custom-order/template-share/get/seller-info/',
    sendOtp: environment.userHost + "/sales-wizz/otp/login/customer/send/",
    otpLogin: environment.userHost +'/sales-wizz/user/v1/customer/login',
    userInfo: environment.userHost +'/sales-wizz/customer/info'
}

export const catalogUrls ={
    catalogueList: environment.gateWayHost + '/gate/sales-wizz/cat/product/catalog-share/get/list/',
    cartCount: environment.gateWayHost + '/gate/sales-wizz/cat/cart/fetch',
    productDetail: environment.gateWayHost +'/gate/sales-wizz/cat/product/buyer/catalog-share/get/',
    productlist: environment.gateWayHost +'/gate/sales-wizz/cat/product/buyer/',
    addToCart: environment.gateWayHost + '/gate/sales-wizz/cat/cart/add/',
    catalogueData: environment.gateWayHost +'/gate/sales-wizz/cat/catalog/share/',
    deleteItem: environment.gateWayHost + '/gate/sales-wizz/cat/cart/product/remove/',
    orderItem: environment.gateWayHost + '/gate/sales-wizz/ord/order/save',
    updateQuantity: environment.gateWayHost +'/gate/sales-wizz/cat/cart/update/'
}
export const customOrderUrls ={
    customSuggestion: environment.gateWayHost +'/gate/sales-wizz/ord/custom-order/suggestion/',
    customField:environment.gateWayHost +'/gate/sales-wizz/cat/custom-order/share/',
    placeCustomOrder: environment.gateWayHost +'/gate/sales-wizz/ord/custom-order/buyer/create',
    userDetails: environment.userHost +'/sales-wizz/customer/update'
}