export interface UserData {
    companyName:string,
    id:string,
    mobileNumber:string,
    name:string,
    status:string,
    email:string

}
export function userdataDto(data: any): UserData{
    return {
        companyName: data['companyName'],
        id: data['id'],
        mobileNumber: data['mobileNumber'],
        name: data['name'],
        status: data['status'],
        email: data['email'],
    }
}