import { Injectable, OnInit } from '@angular/core';
import { ApiService } from '../user-service/api.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catalogUrls, loginUrls } from '../url';
import { SellerDetails, sellerDetailsDto } from '../model/business.model';
import { BehaviorSubject, Observable, Subscription, map, of, tap } from 'rxjs';
import { Cartdata, cartDataDto } from '../model/cart.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessDetailService{

  sellerDetails: BehaviorSubject<SellerDetails | undefined> = new BehaviorSubject<SellerDetails | undefined>(undefined);
  cartData: BehaviorSubject<Cartdata | undefined> = new BehaviorSubject<Cartdata | undefined>(undefined)
  collectionId: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined)
  companyName:BehaviorSubject<string |undefined> =new BehaviorSubject<string | undefined>(undefined)
  
  apiSub: Subscription|undefined

  constructor(
    private api: ApiService,
  ) { }

  getCatalogMetadata(companyName: string, collectionId: string, orderType?: string): void{
    if (!companyName || !collectionId) return;
    let url  = "";
    if(orderType && orderType === "custom"){
      url = loginUrls.sellerInfoCustomOrder;
    } else {
      url = loginUrls.sellerInfo;
    }
    this.apiSub?.unsubscribe();
    this.apiSub = this.api.getData(url + companyName + '/' + collectionId).pipe(
      map((data: any) => {
        const sellerDetails: SellerDetails = {
          ...sellerDetailsDto(data),
          collectionId: collectionId,
          companyName:companyName.replaceAll('_',' ')
        }
        this.sellerDetails.next(sellerDetails);
        return sellerDetails;
      })
    ).subscribe();
  }
  
  getCartCount() {
    this.api.getData(catalogUrls.cartCount).subscribe({
      next: (data: any) => {
        const cartData = cartDataDto(data);
        this.cartData.next(cartData);
        return cartData
      },
      error: (err)=>{
        
      }
    });
  }
  
}

