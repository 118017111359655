import { Injectable } from '@angular/core';
import { BehaviorSubject, skip, tap } from 'rxjs';
import { StorageService } from '../user-service/storage.service';
import { ApiService } from '../user-service/api.service';
import { loginUrls } from '../url';
import { userdataDto } from '../model/userDetail';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userData: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  
  constructor(
    private storage: StorageService,
    private api :ApiService
  ) { }

  fetchUserData(){
    this.api.getData(loginUrls.userInfo).pipe(
      tap({
        next: data=>{
          const userData = userdataDto(data);
          this.userData.next(userData);
        },
        error: err=>{
          this.userData.next(undefined);
        }
      })
    ).subscribe({
      error: (err)=>{}
    });
  }

  isAuthenticated() {
    return new Promise<any>((resolve, reject) => {
      if (!this.storage.getItem("accessToken")) {
        resolve(false);
        return;
      }
      this.userData.pipe(
        skip(1)
      ).subscribe(data => {
        // if (data) {
        //   resolve(true)
        // } else {
        //   resolve(false)
        // }
        resolve(data);
      })
      this.fetchUserData();
    })
  }

}
